<template>
    <div>
        <div class="d-flex" v-if="item.username !== null">
            <div class="leaderboard-crown-parent">
                <v-avatar class="ml-3" size="24">
                    <img :src="c_valid_picture"
                         @error="valid_picture = false"
                         alt=""
                         loading="lazy">
                </v-avatar>
                <v-icon v-if="item.rank === '1'
                                           || item.rank === '2'
                                           || item.rank === '3'"
                        class="leaderboard-crown"
                        :class="{
                                        'amber--text':item.rank === '1',
                                        'blue-grey--text':item.rank === '2',
                                        'brown--text lighten-1':item.rank === '3'}">
                    {{item.rank === "1" ? "mdi-crown" : "mdi-crown-outline"}}
                </v-icon>
            </div>
            <span class="ml-3 font-weight-bold" :class="$variables.v.roles.find(x => x.id === item.roleID).color">{{item.username}}</span>
        </div>
        <span v-if="item.username === null">{{$t("leaderboard.table.deleted-user")}}</span>
    </div>
</template>

<script>
    export default {
        props   : ['item'],
        data() {
            return {
                valid_picture: true,
            }
        },
        computed: {
            c_valid_picture() {
                return this.valid_picture ? this.item.avatar_url : this.$variables.v.profile_default_avatar;
            },
        },
    }
</script>