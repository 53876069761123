<template>
    <div class="animate__animated animate__fadeIn">
        <loading-circle v-if="loading"></loading-circle>
        <div v-if="!loading">
            <v-card-title>{{$t("leaderboard.title")}}</v-card-title>

            <v-card-text v-html="$t('leaderboard.desc')"></v-card-text>

            <v-card-text v-if="$user.user.auth">
                {{$t("leaderboard.your-rank")}} <b>{{user.rank}}</b>
                <v-spacer></v-spacer>
                {{$t("leaderboard.your-points")}} <b>{{user.points}}</b>
            </v-card-text>

            <div>
                <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('leaderboard.search')"
                        :color="$variables.v.theme.secondary"
                        hide-details
                        class="default-textfield-width"
                        @keyup.enter="search_user"></v-text-field>
                <!--Überschriften-->
                <v-data-table
                        :headers="headers"
                        :items="leaderboard"
                        :page.sync="page"
                        :items-per-page="$variables.v.leaderboard_items_per_page"
                        hide-default-footer
                        class="disable-select"
                        @page-count="pageCount = $event"
                        @click:row="open_profile">
                    <template slot="no-data">
                        <div></div>
                    </template>
                    <!--Avatar neben Benutzername-->
                    <template v-slot:item.user="{ item }">
                        <leaderboard-user :item="item"></leaderboard-user>
                    </template>
                </v-data-table>
                <!--Seiten Navigation-->
                <div class="text-center pt-2">
                    <v-pagination
                            v-model="page"
                            :length="pageCount"
                            :color="$variables.v.theme.primary"
                    ></v-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import leaderboard_user from "../components/leaderboard-user";


    export default {
        components: {
            "leaderboard-user": leaderboard_user
        },
        data() {
            return {
                loading    : false,
                search     : "",
                page       : 1,
                pageCount  : 0,
                headers    : [
                    {text: this.$t("leaderboard.table.rank"), value: "rank"},
                    {text: this.$t("leaderboard.table.user"), value: "user"},
                    {text: this.$t("leaderboard.table.points"), value: "points"},
                ],
                leaderboard: [],
                user       : {},
            }
        },
        created() {
            this.init();
        },
        methods   : {
            async init() {
                this.loading = true;

                if (this.$store.leaderboard.length === 0) {
                    let leaderboard = await this.func.ax("/php/getter.php", {
                        func  : "get_leaderboard",
                        search: "",
                    });
                    if (this.func.isObject(leaderboard)) {
                        this.leaderboard        = leaderboard;
                        this.$store.leaderboard = leaderboard;
                    }
                }
                else
                    this.leaderboard = this.$store.leaderboard;

                if (this.$store.leaderboard_own === null) {
                    let leaderboard_own = await this.func.ax("/php/getter.php", {
                        func  : "get_my_leaderboard_position",
                        userid: this.$user.user.id,
                    });
                    if (this.func.isObject(leaderboard_own)) {
                        this.user                   = leaderboard_own;
                        this.$store.leaderboard_own = leaderboard_own;
                    }
                }
                else
                    this.user = this.$store.leaderboard_own;
                this.loading = false;
            },
            open_profile(user) {
                if (user.username !== null)
                    this.$router.push(this.$variables.v.navigation.profile_id + user.userID);
            },
            async search_user() {
                let leaderboard = await this.func.ax("/php/getter.php", {
                    func  : "get_leaderboard",
                    search: this.search,
                });
                if (this.func.isObject(leaderboard)) {
                    this.leaderboard = leaderboard;
                }
            },
        },
        metaInfo() {
            return {
                title: `${this.$t('meta.page-title-unformated')} - ${this.$t('meta.page-title-leaderboard')}`,
                meta : [{
                    name   : "description",
                    content: this.$t("meta.description"),
                }]
            }
        },
    }
</script>